<template>
  <!-- Order Item -->
  <li class="list-group-item d-flex justify-content-between lh-condensed pl-0">
    <h6 class="my-0 my-auto">
      {{ item.item_name }}
      <span class="badge font-weight-bold primary-blue mr-1">{{
        item.quantity
      }}</span>
      <small v-if="item.special_notes" class="d-block text-left mt-3 pl-3">
        Special Notes: {{ item.special_notes }}</small
      >
    </h6>
    <button
      type="button"
      class="delete border-0 font-weight-bold ml-auto d-inline"
      v-if="item.status === 1 && item.device == $cookies.get('X-DEVICE-ID')"
      v-on:click="deleteItem"
    >
      ×
    </button>
  </li>
</template>

<script>
export default {
  name: "OrderItem",
  props: ["item"],
  methods: {
    deleteItem: function() {
      this.axios
        .post(process.env.VUE_APP_DELETE_ITEM_URL, {
          vendor: this.$store.state.vendor.uuid,
          id: this.item.id,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.error = error.message;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style scoped>
.card {
  border-color: none !important;
}
li {
  background-color: white !important;
  color: black;
  border-color: white !important;
}
.delete {
  background: white !important;
  border-color: white !important;
  color: black !important;
  font-size: 1.5rem;
}
h6 {
  font-size: 1.25rem !important;
}
</style>
