<template>
  <!-- Need Help? -->
  <b-row no-gutters style="min-height:75vh;">
    <b-col class="my-auto text-center">
      <h3 class="font-weight-bold mt-3">Need help with your order?</h3>
      <button class="font-weight-bold btn mt-3">Request Help</button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Help'
}
</script>

<style scoped>
.badge{
vertical-align:top;
}
</style>
