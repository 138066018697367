<template>
  <!-- Nav -->
  <b-row no-gutters>
    <b-col>
      <nav id="nav" class="navbar navbar-light mt-1 mb-1">
        <router-link
          class="font-weight-bold btn mr-auto primary-blue"
          to="/menu"
          >Back</router-link
        >
        <router-link
          class="font-weight-bold btn ml-auto primary-blue"
          to="/payment"
          v-if="displayPaymentButton"
          >Pay Now</router-link
        >
      </nav>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "StatusNav",
  props: ["displayPaymentButton"],
};
</script>
