<template>
  <!-- Checkout/Status -->
  <b-container fluid>
    <StatusNav :displayPaymentButton="emptyCart && items.length"></StatusNav>
    <Tracker
      :items="items"
      :data="orderItems"
      v-if="emptyCart && items.length"
    ></Tracker>
    <Review v-bind:data="orderReview" v-if="!emptyCart"></Review>
    <Help v-if="emptyCart && !items.length"></Help>
  </b-container>
</template>

<script>
import StatusNav from "../components/StatusNav.vue";
import Tracker from "../components/Tracker.vue";
import Review from "../components/Review.vue";
import Help from "../components/Help.vue";

export default {
  name: "Status",
  components: {
    StatusNav,
    Tracker,
    Review,
    Help,
  },
  computed: {
    items: function() {
      return this._.flatMap(this.$store.state.order.tickets, function(obj) {
        return obj.items;
      });
    },
    myItems: function() {
      // eslint-disable-next-line no-unused-vars
      var device = this.$cookies.get("X-DEVICE-ID");
      console.log(device);
      return this._.flatMap(
        this._.pickBy(this.items, function(item) {
          return item.device == device;
        }),
        function(object) {
          return object;
        }
      );
    },
    myTickets: function() {
      // eslint-disable-next-line no-unused-vars
      var device = this.$cookies.get("X-DEVICE-ID");
      return this._.sortBy(this.$store.state.order.tickets, function(obj) {
        return obj.items.some((item) => item.device === device);
      });
    },
    orderReview: function() {
      return this._.groupBy(
        this._.sortBy(
          this._.pickBy(this.myItems, function(item) {
            return item.status == 1;
          }),
          "category_type"
        ),
        "category_name"
      );
    },
    orderItems: function() {
      return this._.groupBy(
        this._.orderBy(
          this._.orderBy(this.myItems, "category_type", "asc"),
          "status",
          "asc"
        ),
        "get_status_display"
      );
    },
    emptyCart: function() {
      return this._.isEmpty(this.orderReview);
    },
  },
};
</script>
