<template>
  <b-row no-gutters style="min-height:75vh;">
    <!-- Review Order -->
    <b-col class="my-auto">
      <h3 class="font-weight-bold mt-3 mb-4 text-center">Review Order</h3>
      <div
        v-for="(items, category) in data"
        class="d-block"
        v-bind:key="'category-' + category"
      >
        <OrderItem
          v-for="item in items"
          v-bind:item="item"
          v-bind:key="'orderitem-' + item.id"
        ></OrderItem>
      </div>
      <button
        class="font-weight-bold text-center mx-auto d-block btn mt-3 primary-blue"
      >
        <span v-on:click="submitItem" v-if="!this.loading">
          Place Order
        </span>
        <b-spinner
          small
          v-if="this.loading"
          type="grow"
          style="vertical-align:middle;"
        ></b-spinner>
      </button>
    </b-col>
  </b-row>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";

export default {
  name: "Review",
  components: {
    OrderItem,
  },
  data: function() {
    return {
      loading: false,
    };
  },
  props: ["data"],
  methods: {
    submitItem: function() {
      this.loading = true;

      this.axios
        .post(process.env.VUE_APP_SUBMIT_ORDER_URL, {
          vendor: this.$store.state.vendor.uuid,
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.badge {
  vertical-align: top;
}
.spinner-grow {
  background: white !important;
}
</style>
