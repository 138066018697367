<template>
  <!-- Tracker -->
  <b-row no-gutters style="min-height:65vh;">
    <b-col class="my-auto text-center">
      <h3 class="font-weight-bold mt-3 mb-4 text-center">
        Track Order
      </h3>
      <div
        v-for="(items, category) in data"
        class="d-block"
        v-bind:key="'category-' + category"
      >
        <div class="mt-3 mb-3 text-left font-weight-bold">
          {{ category }}...
          <span class="font-weight-normal float-right">
            {{ items.length }} Item<span v-if="items.length > 1">s</span></span
          >
        </div>
        <OrderItem
          v-for="item in items"
          v-bind:item="item"
          v-bind:key="'orderitem-' + item.id"
        ></OrderItem>
      </div>
      <router-link
        tag="button"
        class="btn font-weight-bold mt-3 primary-blue"
        to="/menu/"
        >Order Again</router-link
      >
    </b-col>
  </b-row>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";

export default {
  name: "Tracker",
  props: ["items", "data"],
  components: {
    OrderItem,
  },
};
</script>
<style>
li {
  background-color: white !important;
  color: black;
  border-color: white !important;
}
a {
  color: black !important;
}
.active-btn {
  color: white !important;
}
</style>
